
<template>
<div>
  <is-loading v-if="isLoading" />
  <div v-else class="row account-page g-4">
    <div class="col-md-4 order-md-2">
      <div class="card overflow-hidden position-sticky" style="top: 90px;">
        <div class="bg-primary py-4">
        </div>
        <div class="card-body text-center">
          <a href="javascript:void(0)" class="avatar rounded-circle avatar-lg card-avatar card-avatar-top">
            <img @click="showProfileImg(activeUser.image)" :src="absoluteUrl(activeUser.image)" class="cursor-pointer avatar-img rounded-circle border border-4 border-card" alt="...">
          </a>
            <small>
              <span class="text-success">●</span> Online
            </small>
          <h3 class="card-title">
            <a href="javascript:void(0)">{{activeUser.first_name}} {{activeUser.last_name}}</a>
          </h3>
          <p class="small text-muted mb-3">
            Joined on {{$filters.date(activeUser.created_at)}}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row g-4">
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <h6 class="mb-3 text-uppercase bg-light p-2">
                Basic Info
              </h6>
              <account-profile-form :editItem="activeUser" />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <h6 class="mb-3 text-uppercase bg-light p-2">
                  Password
              </h6>
              <account-password-form />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import AccountProfileForm from '@/components/forms/AccountProfileForm.vue'
import AccountPasswordForm from '@/components/forms/AccountPasswordForm.vue'

export default {
  name: "user-edit",
  components: {
    IsLoading,
    AccountPasswordForm,
    AccountProfileForm,
  },
  data(){
    return {
      isLoading: true
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
  },
  created() {
    this.$store.dispatch("fetchActiveUser")
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: "/error-404"})
        }
    })
  },
}

</script>
<style scoped>
.user-photo{
 width:10rem;
}
</style>
